import {createWidgetPreloader} from '../source/preloader'
import {WidgetEvent, WidgetEventName} from '../source/tamaro/lib/WidgetEventer'

///////////////////////////////////////////////////////////////////////////////

let widgetPreloader = createWidgetPreloader({
  resolvers: {
    initialConfig: async () => {
      return {
        // debug: false,
        customConfig: require('../config/config.**.yml'),
      }
    },
    preloaderCSS: () => import('./styles/preloader'),
    widgetCSS: () => import('./styles/widget'),
  },
})

///////////////////////////////////////////////////////////////////////////////

// // Subscribe to all events
// let eventHandler = (event: WidgetEvent) => console.log(event.eventName, event.data)
//
// for (let eventName of Object.keys(widgetPreloader.events)) {
//   widgetPreloader.events[eventName as WidgetEventName].subscribe(eventHandler)
// }

///////////////////////////////////////////////////////////////////////////////

window['rnw'] = window['rnw'] || {}
window['rnw']['tamaro'] = widgetPreloader
